import React from 'react';

import BlogRedirect404 from '../components/blog-redirect-404';
import HomeRedirect404 from '../components/home-redirect-404';
import Seo from '../components/seo';

const NotFoundPage = ({ location }) => {
  // const path = location && location.pathname;
  // const pathSegments = path.split('/');
  // const isBlogUrl = pathSegments && pathSegments.length >= 4;
  // let newRedirectUrl;

  // if (isBlogUrl) {
  //   newRedirectUrl = `https://k2vacademy.com/${pathSegments[1]}/${pathSegments[2]}/${pathSegments[4]}`;
  // }

  //console.log({location, pathSegments, isBlogUrl, newRedirectUrl});

  return (
    <>
      <Seo title="Kasun Kodagoda" />
      <HomeRedirect404 />
      {/* <BlogRedirect404 redirectUrl={newRedirectUrl} /> */}
      {/* { isBlogUrl && <BlogRedirect404 redirectUrl={newRedirectUrl} /> } */}
      {/* { !isBlogUrl && <HomeRedirect404 /> } */}
    </>
  )
};

export default NotFoundPage;
