import React from 'react';
import { Link } from 'gatsby';

const HomeRedirect404 = () => {

  const buttonStyle = {
    background: '#04620b',
    border: 0,
    padding: '10px 35px',
    color: '#fff',
    transition: '0.4s',
    borderRadius: '50px',
    fontSize: '1.5em'
  };

  return (
    <section style={{padding: '200px 0'}} >
      <div className="container">
        <div className="section-title">
          <h1 style={{ fontSize: '6em' }}>404!</h1>
          <p style={{ fontSize: '1.5em' }}>Sorry, page not found</p>
          <p>Use the navigation above or the button below to get back and track.</p>
          <div className="row" style={{paddingTop: '50px'}}>
            <div className="col-lg-4"></div>
            <div className="col-lg-4">
              <Link to="/" style={buttonStyle}>Back to Home</Link>
            </div>
            <div className="col-lg-4"></div>
          </div>
        </div>
      </div>
    </section>

  );
}

export default HomeRedirect404;